<template>
  <v-container class="tasks pb-8 fadeInFast">
    <HeadlineTableView
        :icon="'mdi-format-list-checks'"
        :headline="'Aufgaben'"
        :button-icon="'mdi-format-list-checks'"
        :button-text="'Neue Aufgabe'"
        @buttonClick="openDialogCreateTask()"

    ></HeadlineTableView>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-card flat class="rounded-xl pa-2 pa-sm-4">
      <div>
        <Tab
            :tabs="['Offene','Erledigte']"
            :register="register"
            @changed="register=$event"
        >
        </Tab>
        <div v-if="loaded">
          <TableViewTasks
              v-if="register === 0"
              :headLines="headLines"
              :rows="undoneTasks"
              :textNoResults="'Hier werden offene Aufgaben angezeigt.'"
              @deleteEntries="deleteTask"
              @openDetail="openDetailTask"
              @setDone="setTaskDone($event)"
          ></TableViewTasks>
          <TableViewTasks
              v-if="register === 1"
              :headLines="headLines"
              :rows="doneTasks"
              :textNoResults="'Hier werden abgeschlossene Aufgaben angezeigt.'"
              @deleteEntries="deleteTask"
              @openDetail="openDetailTask"
          ></TableViewTasks>
        </div>
        <Spinner v-else></Spinner>
      </div>
      <CreateTask
          :dialog="dialog"
          @hideDialog="hideDialog"
      ></CreateTask>
    </v-card>
  </v-container>

</template>
<script>

import {mapGetters} from "vuex"
import {error, msgObj, success} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");
const TableViewTasks = () => import("@/components/entities/Task/TableViewTasks");
const CreateTask = () => import("@/components/dialog/createDialogs/CreateTask");
const Spinner = () => import("@/components/generalUI/Spinner");
const Tab = () => import("@/components/generalUI/Tab");
const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
  name: 'Tasks',
  components: {
    Message,
    HeadlineTableView,
    TableViewTasks,
    CreateTask,
    Spinner,
    Tab
  },

  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('auth/checkLogin', {}).then((res) => {
      this.$store.dispatch('task/getTasks', {
        uid: res
      }).then(() => {
        this.loaded = true
      }).catch((err) => {
        this.message = error(err)
      })
    })
  },
  computed: {
    ...mapGetters('task', {
      tasks: 'tasks',
      undoneTasks: 'undoneTasks',
      doneTasks: 'doneTasks'
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
  },
  data() {
    return {
      dialog: false,
      register: 0,
      loaded: false,
      message: msgObj(),
      headLines: [
        {text: "Betreff", sort: 'subject'},
        {text: "Priorität", sort: 'prio'},
        {text: "Fällig am", sort: 'date'},
      ],
    }
  },
  methods: {
    openDialogCreateTask() {
      this.dialog = true
    },
    hideDialog() {
      this.dialog = false
    },
    setTaskDone(payload) {
      this.$store.dispatch('task/setTaskDone', {
        uid: this.user.id,
        taskId: payload.id
      }).catch((err) => {
        this.message = error(err)
      })
    },
    async deleteTask(ids) {
      let i = 0
      this.loaded = false
      for (let id of ids) {
        this.$store.dispatch('task/deleteTasks', {
          uid: this.user.id,
          taskId: id,
        }).catch((err) => {
          this.message = error(err)
        }).finally(() => {
          if (i === ids.length - 1) {
            this.loaded = true
            if (ids.length === 1) {
              this.message = success("Aufgabe erfolgreich gelöscht.")
            } else {
              this.message = success(ids.length + "Aufgaben erfolgreich gelöscht.")
            }
          }
        })
        await delay(100)
        i++
      }
    },
    openDetailTask(id) {
      this.$router.push("tasks/task/" + id)
    },
  }
}
</script>
